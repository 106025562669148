import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from '@modules/modal';

import {DropdownComponent} from './components/dropdown/dropdown.component';
import {SelectComponent} from './components/select/select.component';
import {SelectOptionComponent} from './components/select-option/select-option.component';

@NgModule({
  declarations: [SelectComponent, SelectOptionComponent, DropdownComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ScrollingModule,
    OverlayModule,
    PortalModule,
    ModalModule,
  ],
  exports: [SelectComponent, SelectOptionComponent],
})
export class SelectModule { }
