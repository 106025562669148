<div class="form__block" #dropreference [ngClass]="wrapperStyling">
  <label class="form__block-text form__block-text__select" (click)="labelClickHandler($event)">
    <span [class.required]="required">{{ label }}</span>
    <input
      class="form__input"
      #input
      [placeholder]="placeholder || label"
      [disabled]="disabled"
      [readonly]="readonly"
      [value]="value"
      [hidden]="hiddenInput"
      (click)="showDropdown($event)"
      (keydown)="onKeyDown($event)"
      (blur)="onTouched()"
      autocomplete="off" />
  </label>
  <!--  <span class="dropdown-arrow" [class.dropdown-arrow_position]="arrowIndent" (click)="onDropMenuIconClick($event)"></span>-->
  <i
    class="icon-down-arrow-sm dropdown-arrow"
    [class.dropdown-arrow_position]="arrowIndent"
    (click)="onDropMenuIconClick($event)"></i>
  <app-dropdown [reference]="parentContainer || input" [readonly]="disableSelect" #dropdownComp>
    <div
      class="dropdown-options-container"
      [class.container-fixed]="!search"
      #dropDownContainer
      [style.min-width.rem]="minWidth">
      <ng-container *ngIf="search; else defaultList">
        <div class="search-option">
          <label class="search-input">
            <span class="search-input-icon"><i class="icon-search"></i></span>
            <input type="text" [formControl]="searchControl" [placeholder]="searchTitle" />
          </label>
        </div>
        <cdk-virtual-scroll-viewport
          itemSize="40"
          class="scroll-viewport"
          [minBufferPx]="250"
          [maxBufferPx]="400"
          [style.height.px]="maxDisplayedElements * 40 - 30">
          <ng-container *cdkVirtualFor="let item of items$ | async">
            <app-select-option
              [value]="item.value"
              [isSelected]="item?.selected"
              notSelectable="true"
              (click)="selectOption(item)">
              {{ item?.displayedContent }}
            </app-select-option>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </ng-container>

      <ng-template #defaultList>
        <ng-content select="app-select-option"></ng-content>
      </ng-template>
    </div>
  </app-dropdown>
</div>
