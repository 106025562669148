import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {debounceTime, map, startWith, takeUntil, tap} from 'rxjs/operators';

import {SEAZONE_CONTROL_CONFIG, SeazoneControlSettings} from '@controls/shared';

import {SelectService} from '../../service/select.service';
import {DropdownComponent} from '../dropdown/dropdown.component';
import {SelectOptionComponent} from '../select-option/select-option.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    SelectService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent<T = any> implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor {

  selectedOption: SelectOptionComponent;
  value = '';
  wrapperStyling: string[] = [];

  private keyManager: ActiveDescendantKeyManager<SelectOptionComponent>;
  private readonly destroyStream$ = new Subject<void>();

  searchControl = new UntypedFormControl(null);

  items$: Observable<SelectOptionComponent[]> = of([]);

  readonly maxDisplayedElements = 6;

  private get optionsArray(): SelectOptionComponent[] {
    const options = this.options;
    if (!options) {
      return [];
    }
    return options.toArray();
  }

  private get containerHeight(): number {
    const container = this.dropDownContainer;
    return container && container.nativeElement.offsetHeight || 272;
  }

  private get selectedIndex(): number {
    const selected = this.selectedOption;
    if (!selected) {
      return 0;
    }
    const index = this.optionsArray.findIndex(option => option.value === selected.value);
    return index || 0;
  }

  private get selectedHeight(): number {
    const selected = this.selectedOption;
    if (!selected) {
      return 0;
    }
    const element = selected.elementRef.nativeElement;
    return element.offsetHeight || 0;
  }

  private get scrollTop(): number {
    const containerHalf = this.containerHeight / 2;
    return (this.selectedIndex * this.selectedHeight) - containerHalf;
  }

  @Input() label = '';
  @Input() placeholder;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = true;
  @Input() hiddenInput = false;
  @Input() parentContainer: HTMLElement;
  @Input() arrowIndent = false;
  @Input() selectedValue: T;
  @Input() search = false;
  @Input() searchTitle = 'Search';
  @Input() minWidth: number | null = null;
  @Input() disableSelect = false;
  @Output() selectionChange = new EventEmitter<T>();
  @Output() dropdownShown = new EventEmitter<void>();

  @ViewChild('input') input: ElementRef;
  @ViewChild('dropdownComp') dropdown: DropdownComponent;
  @ViewChild('dropDownContainer') dropDownContainer: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;

  @ContentChildren(SelectOptionComponent) options: QueryList<SelectOptionComponent>;

  private compareWithFn = (o1: any, o2: any): boolean => o1 === o2;

  @Input()
  get compareWith() {
    return this.compareWithFn;
  }

  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (typeof fn !== 'function') {
      return;
    }
    this.compareWithFn = fn;
  }

  onChangeFn = (_: any) => {
  };
  onTouchedFn = () => {
  };

  constructor(
    private readonly selectService: SelectService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(SEAZONE_CONTROL_CONFIG) public readonly seazoneControlSettings: SeazoneControlSettings,
  ) {
    this.selectService.register(this);
  }

  ngOnInit(): void {
    this.updateStyling();
    this.items$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      map(value => !value ? this.optionsArray :
        this.optionsArray.filter(option => option.displayedContent.toLowerCase().trim().startsWith(value.toLowerCase()))),
      tap(() => this.cdr.detectChanges()),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedValue' in changes) {
      const selectedOption = this.findSelectedOption(this.selectedValue);
      if (selectedOption) {
        this.setSelectedOption(selectedOption);
      }
    }
  }

  ngAfterViewInit() {
    this.options.changes.pipe(
      startWith(this.options),
      takeUntil(this.destroyStream$),
    ).subscribe(() => {
      this.searchControl.setValue('');
      const selectedOption = this.findSelectedOption(this.value || this.selectedValue);
      if (selectedOption) {
        this.setSelectedOption(selectedOption, false);
      }
      this.initKeyManager(this.options);
    });
  }

  ngOnDestroy(): void {
    const destroy = this.destroyStream$;
    destroy.next();
    destroy.unsubscribe();
    this.cdr.detach();
  }

  private setSelectedOption(option: SelectOptionComponent, emitEvent = true): void {
    this.selectedOption = option;
    this.selectedValue = option.value;
    this.value = option.display || option.displayedContent || option.value || '';
    if (emitEvent) {
      this.selectionChange.emit(option.value);
    }
    this.cdr.detectChanges();
  }

  private initKeyManager(options: QueryList<SelectOptionComponent>): void {
    this.keyManager = new ActiveDescendantKeyManager(options)
      .withHorizontalOrientation('ltr')
      .withVerticalOrientation()
      .withWrap();
  }

  labelClickHandler(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  showDropdown(event: MouseEvent | null = null) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!this.options.length) {
      return;
    }
    this.dropdown.show();
    this.dropdownShown.emit();
    if (this.search) {
      this.scrollViewport.scrollToIndex(this.selectedIndex);
    } else {
      this.dropDownContainer.nativeElement.scrollTop = this.scrollTop - this.selectedHeight * 2;
    }

    const keyManager = this.keyManager;
    this.selectedValue ? keyManager.setActiveItem(this.selectedOption) : keyManager.setFirstItemActive();
  }

  hideDropdown(): void {
    this.dropdown.hide();
  }

  onDropMenuIconClick(event: UIEvent) {
    event.stopPropagation();
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.click();
    }, 10);
  }

  onKeyDown(event: KeyboardEvent) {
    if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
      if (!this.dropdown.showing) {
        this.showDropdown();
        return;
      }

      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }

    if (event.key === 'Enter' || event.key === ' ') {
      const activeItem = this.keyManager.activeItem;
      if (activeItem) {
        this.setSelectedOption(activeItem);
      }
      this.hideDropdown();
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.hideDropdown();
    } else if (['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left']
      .indexOf(event.key) > -1) {
      this.keyManager.onKeydown(event);
    } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
      this.hideDropdown();
    }
  }

  selectOption(option: SelectOptionComponent) {
    this.keyManager.setActiveItem(option);
    this.setSelectedOption(option);
    this.input.nativeElement.focus();
    this.hideDropdown();
    this.onChange();
  }

  writeValue(value: any): void {
    const selected = this.findSelectedOption(value);
    this.selectedValue = selected || value;
    if (selected) {
      const selectedValue = selected.value;
      if (selectedValue !== null && selectedValue !== undefined) {
        this.setSelectedOption(selected, false);
        return;
      }
    }
    this.value = '';
    this.cdr.detectChanges();
  }

  private findSelectedOption(value: string | number | T): SelectOptionComponent | null {
    return this.optionsArray.find(option => option.value !== null && this.compareWithFn(option.value, value)) || null;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  onTouched() {
    this.onTouchedFn();
  }

  onChange() {
    this.onChangeFn(this.selectedValue);
  }

  private updateStyling(): void {
    const classList: string[] = [];
    const settings = this.seazoneControlSettings;
    if (settings.direction === 'row') {
      classList.push('form__block--row');
    }
    this.wrapperStyling = [...classList, ...settings.additionalClasses || ''];
  }
}
