import {Injectable} from '@angular/core';

import {SelectComponent} from '../components/select';

@Injectable()
export class SelectService {

  private select: SelectComponent;

  register(select: SelectComponent): void {
    this.select = select;
  }

  getSelect(): SelectComponent {
    return this.select;
  }
}
