import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

import {SelectService} from '../../service/select.service';
import {SelectComponent} from '../select';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent implements AfterViewInit {

  @Input() value: any;
  @Input() display: string | number = '';
  @Input() notSelectable = false;
  @Input() isSelected = false;

  displayedContent: string;

  @ViewChild('option') option: ElementRef;

  @HostBinding('class.selected')
  get selected(): boolean {
    return this.select.selectedOption === this && !this.notSelectable || this.isSelected;
  }

  @HostBinding('class.active') active = false;

  private select: SelectComponent;

  constructor(
    private readonly selectService: SelectService,
    public readonly elementRef: ElementRef,
  ) {
    this.select = this.selectService.getSelect();
  }

  ngAfterViewInit(): void {
    this.setDisplayedValue();
  }

  private setDisplayedValue(): void {
    const option = this.option;
    const nativeElement = option && option.nativeElement;
    this.displayedContent = nativeElement.innerText || this.display;
  }

  getLabel(): string {
    return this.value;
  }

  setActiveStyles(): void {
    this.active = true;
  }

  setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.notSelectable) {
      this.select.selectOption(this);
    }
  }

}
