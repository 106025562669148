import {Overlay, OverlayConfig, OverlayRef, ViewportRuler} from '@angular/cdk/overlay';
import {CdkPortal} from '@angular/cdk/portal';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {CustomOverlayRef} from '@modules/modal/classes/custom-overlay-ref';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {addScroll, removeScroll} from '@helpers/scroll-top';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {

  showing = false;
  protected overlayRef: OverlayRef;

  private readonly destroySStream$ = new Subject<void>();

  @Input() reference: HTMLElement;
  @Input() readonly = false;
  @ViewChild(CdkPortal) portal: CdkPortal;

  constructor(
    protected overlay: Overlay,
    @Inject(CustomOverlayRef) private readonly customOverlayRef: CustomOverlayRef,
    private readonly viewportRuler: ViewportRuler,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    const destroy = this.destroySStream$;
    destroy.next();
    destroy.complete();
  }

  show() {
    if (this.readonly) {
      return;
    }
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.portal);
    this.syncWidth();
    this.overlayRef.backdropClick().pipe(
      takeUntil(this.destroySStream$),
    ).subscribe(() => this.hide());
    this.showing = true;
    const isModal = !!this.customOverlayRef.overlay;
    if (isModal) {
      return;
    }
    removeScroll();
  }

  hide() {
    this.overlayRef.detach();
    this.showing = false;
    const isModal = !!this.customOverlayRef.overlay;
    if (isModal) {
      return;
    }
    addScroll();
  }

  @HostListener('window:resize')
  onWinResize() {
    this.syncWidth();
  }

  protected getOverlayConfig(): OverlayConfig {

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }, {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      }]);

    const scrollStrategy = this.overlay.scrollStrategies.block();

    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({width: refRect.width});
  }

}
